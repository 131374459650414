.mt-100 {
  margin-top: 100px;
}
.mt-140 {
  margin-top: 140px;
}
.mt-176 {
  margin-top: 176px;
}
.mt-70 {
  margin-top: 70px;
}
.mt-30 {
  margin-top: 30px;
}
.mr-30 {
  margin-right: 30px;
}
.mt-20 {
  margin-top: 20px;
}

.ml-12 {
  margin-left: 12px;
}

.p-56 {
  padding: 56px;
}
.r-0 {
  right: 0;
}
.px-12 {
  padding-left: 12px;
  padding-right: 12px;
}
.px-20 {
  padding-left: 20px;
  padding-right: 20px;
}
.px-24 {
  padding-left: 24px;
  padding-right: 24px;
}

.py-24 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.px-32 {
  padding-left: 32px;
  padding-right: 32px;
}
.px-48 {
  padding-left: 48px;
  padding-right: 48px;
}

.w-100 {
  width: 100%;
}
.d-flex {
  display: flex;
}
