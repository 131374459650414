.post-layer {
  color: #fff;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;

  .brg-layer {
    background-color: #000000;
    opacity: 0.6;
    height: 100%;
    width: 100%;
    position: absolute;
  }
  .post-content {
    top: 10%;
    position: relative;
    // height: 100%;
    padding: 12px;
    .title {
      font-size: 32px;
      font-weight: 800;
    }
    .sub-title {
      font-size: $text-md;
      font-weight: 800;
    }

    p,
    .hashtag {
      font-size: $text-sm;
    }
    .location,
    .time {
      font-size: 10px;
    }
    p {
      margin-top: 14px;
    }
    .location {
      margin-top: 30px;
    }
  }
}

// event
.event-page {
  .form-group .input-group-addon {
    top: 25px;
    transform: unset;
    bottom: 8px;
    right: 25px;
  }
  .search-input {
    font-weight: 600;
    position: fixed;
    top: 0;
    right: 0;
    // width: calc(100vw - 50px);

    z-index: 99999;
    background: #ffffff;
    width: 100%;
    height: 60px;
    padding-bottom: 8px;
    .form-control {
      width: calc(100vw - 50px);
      margin-left: auto;
      margin-right: auto;
      height: 100%;
      padding-left: 15px;
      padding-right: 30px;
      padding-bottom: 5px;
      padding-top: 30px;
    }
  }
  .user-tab {
    // position: fixed;
    // top: 50px;
    // width: 100%;
  }
  .tab {
    // margin-top: 150px;
    margin-top: 20px;
  }
}
//text
.text-container {
  .search-input {
    display: flex;

    .input-group-addon {
      top: -8px;
    }
    label {
      word-break: keep-all;
      line-height: 25px;
      margin-left: 5px;
      margin-right: 5px;
    }
    input:last-child {
      max-width: calc(100vw / 5);
    }
  }
}

.user-item {
  width: 100%;
  display: flex;
  float: left;
  // justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  .user-ava img {
    height: 32px;
    width: 32px;
    object-fit: cover;
    border-radius: 100%;
    margin-right: 12px;
  }
  .btn {
    margin-left: 12px;
    padding: 7px 15px;
  }
}
.post-item {
  &.minimal {
    max-height: 75px;
    .image-layer {
      max-height: 75px;
    }
    img {
      max-height: 75px;
    }
    .post-content {
      .post-title {
        font-size: 12px;
        line-height: 16px;
      }
    }
    &:after {
      right: 26px;
      background-image: none;
      background-color: #ffffff;
      border-radius: 50%;
      height: 8px;
      width: 8px;
      top: -4px;
    }
    &:before {
      right: 26px;
      background-image: none;
      background-color: #ffffff;
      border-radius: 50%;
      height: 8px;
      width: 8px;
      bottom: -4px;
    }
    .post-action-item {
      &.right {
        bottom: 25px;
      }
      svg {
        width: 1rem;
        height: 1rem;
      }
    }
  }
  img {
    border-radius: 8px;
    object-fit: cover;
  }
  margin-bottom: 21px;
  &:after {
    content: "";
    background-image: url(../components/image/post/Ellipse.svg);
    // background-color: #ffffff;
    height: 8px;
    width: 32px;
    position: absolute;
    z-index: 1;
    // border-radius: 100%;
    top: 0;
    right: 56px;
    transform: rotate(180deg);
    filter: brightness(0) saturate(100%) invert(100%) sepia(54%) saturate(2%)
      hue-rotate(348deg) brightness(114%) contrast(101%);
  }
  &:before {
    content: "";
    background-image: url(../components/image/post/Ellipse.svg);
    // background-color: #ffffff;
    height: 8px;
    width: 32px;
    position: absolute;
    z-index: 1;
    // border-radius: 100%;
    bottom: 0;
    right: 56px;
    filter: brightness(0) saturate(100%) invert(100%) sepia(54%) saturate(2%)
      hue-rotate(348deg) brightness(114%) contrast(101%);
  }
}
// timeline
.timeline-page {
  margin-bottom: 40px;
}
.timeline-header {
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  z-index: 99999;
  background: #ffffff;
  width: 100%;
  left: 0;
  padding: 12px 10px;
  .action {
    display: flex;
    margin-left: auto;
    .action-item {
      margin-left: 15px;
    }
    // .action-item svg path {
    //   stroke: #dddddd;
    // }
  }
}
.post-item {
  &.ticket {
    height: 90px;
    img,
    .image-layer {
      height: 90px;
    }
    .post-content {
      display: flex;
      justify-content: center;
      align-items: center;
      height: calc(100% - 20px);
      .post-title {
        text-align: center;
        font-size: $text-sm;
        max-width: unset;
        margin-left: 45px;
        margin-right: 45px;
        line-height: 18px;
      }
    }
    .post-footer {
      .post-action {
        margin-right: unset;
      }
    }
    &:after {
      opacity: 0.3;
      color: #ffffff;
      content: close-quote;
      font-size: 150px;
      background-color: unset;
      border-radius: unset;
      right: 0;
      height: auto;
      width: auto;
      bottom: -115px;
      top: unset;
    }

    &:before {
      opacity: 0.3;
      color: #ffffff;
      content: open-quote;
      font-size: 150px;
      background-color: unset;
      border-radius: unset;
      left: 0;
      height: auto;
      width: auto;
      top: -50px;
    }
  }
}
.post-item-bgr {
  height: 100%;
  border-radius: 8px;
}
.ticket-page {
  padding-left: 7px;
  padding-right: 7px;
  .post-item {
    &.ticket {
      margin-top: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:after,
      &:before {
        opacity: 0.1;
        color: #000000;
      }
    }
    .post-content {
      color: #000000;
      display: contents;
      .post-title {
        font-size: $text-md;
      }
    }
  }
  .post-images {
    margin-top: 28px;
    margin-bottom: 42px;
    img {
      width: 75px;
      height: 75px;
      object-fit: cover;
      border-radius: 8px;
      margin: auto;
    }
  }

  .post-location {
    font-size: $text-sm;
    color: #000000;
    display: flex;
    img {
      width: 24px;
      height: 24px;
      object-fit: cover;
      border-radius: 100%;
      margin-right: 5px;
    }
  }
  .post-action {
    margin-right: unset;
    .post-action-item {
      margin-right: 0;
      margin-left: 9.75px;
      color: #000000;
      svg path {
        stroke: #000000;
      }
    }
  }
}
//stage
.toy-mock-container {
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;

  .toy-mock {
    margin: auto;
    margin-top: 50%;
    width: 100px;
    height: auto;
    // background: #ffffff;
    // box-shadow: 0 14px 26px -12px $color-primary-d1,
    //   0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px $color-primary-d2;
  }
}
.loader {
  position: absolute;
  z-index: 99;
  height: 100%;
  top: 0;
  object-fit: cover;
}
.toy-detail-modal {
  z-index: 300000;
  width: 95%;
  position: absolute;
  .modal-body {
    padding: 0;
  }
  .toy-layer {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.8) 100%
    );
    border-radius: 0px 0px 8px 8px;
    position: absolute;
    width: 100%;
    height: 50%;
    bottom: 0;
  }
  img {
    height: 430px;
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
  }

  .toy-content {
    z-index: 1;
    position: absolute;
    padding: 10px;
    width: 100%;
    height: 100%;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    .toy-body {
      margin-top: auto;
      h5 {
        font-size: 10px;
      }
    }
    .title {
      font-size: 28px;
      font-weight: 800;
    }
    .sub-title {
      margin-top: 16px;
      font-size: 16px;
      font-weight: 800;
    }
  }
  .toy-action {
    display: flex;
    .toy-action-item {
      margin-top: 24px;
      font-size: $text-sm;
      font-weight: 800;
      p {
        width: 75%;
      }
    }
  }
}
.doc {
  strong {
    margin-top: 10px;
  }
  p {
    margin-bottom: 8px;
  }
}
.post-container {
  .toy-mock-container {
    position: relative;
    img {
      // max-width: 200px;
    }
  }
  .post-item.sticky-item {
    position: sticky;
    top: 0;
  }
}
