@import "var";
@import "font";
@import "form";
@import "spacing";

.text-title {
  font-size: $text-sm;
  font-weight: 600;
  svg {
    margin-right: 5px;
  }
}

.text-sm {
  font-size: $text-sm;
}
.text-md {
  font-size: $text-md;
}
.text-lg {
  font-size: $text-lg;
}
.text-primary {
  color: $color-primary;
}

.text-secondary {
  color: $color-secondary;
}
.text-w-600 {
  font-weight: 600;
}

.text-w-800 {
  font-weight: 800;
}
.bgr-gradient-primary {
  background: $gradient-primary;
}
.bgr-gradient-green {
  background: $gradient-green;
}
.bgr-gradient-brown {
  background: $gradient-brown;
}
.btn {
  border: none;
  border-radius: 23px;
  position: relative;
  padding: 7px 30px;
  font-size: $text-sm;
  font-weight: 600;
  letter-spacing: 0;
  will-change: box-shadow, transform;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),
    background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}
.btn-add {
  background: #ffffff;
  border-radius: 100%;
  height: 64px;
  width: 64px;
  display: flex;
  svg {
    margin: auto;
  }
}

.btn-primary {
  background-color: $color-primary;
  color: #ffffff;
}
.btn.btn-primary:hover {
  box-shadow: 0 14px 26px -12px $color-primary-d1,
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px $color-primary-d2;
}
.btn-secondary {
  border: 1px solid $color-primary;
}
.btn-danger {
  border: 1px solid $color-danger;
  color: $color-danger;
}
.btn-round {
  height: 32px;
  width: 32px;
  padding: 0;
}
.btn-gray {
  background-color: #d9d9d9;
}

.text-right {
  text-align: right;
}
.placeholder-black ::placeholder {
  color: #000000;
  opacity: 1;
}

.alert-modal {
  width: 85%;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 50;
  .modal-body {
    padding: 20px 18px;
    min-height: 104px;
  }
}
.p-fixed {
  position: fixed !important;
}

.btn-post {
  border-radius: 100%;
  padding: 5px;
  background: $gradient-primary;
}
body {
  // margin-bottom: 40px;
}
.tab {
  margin-bottom: 50px;
}
.pre-loader {
  .loader-img {
    z-index: 10;
    width: 100px;
    height: 100px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    img {
      animation-name: spin;
      animation-duration: 1000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }
  .image-layer {
    // background: rgba(0, 0, 0, 0.7);
    background: #ffffff;
    position: absolute;
    // opacity: 0.7;
    z-index: 0;
    z-index: 9;
    height: calc(100% - 38px);
    bottom: 38px;
  }
}
.sub-modal {
  position: relative;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.hide {
  visibility: hidden;
}
.show {
  visibility: visible;
}