.stage-setting-page {
  .nav {
    li a {
      cursor: pointer;
    }
  }
  background-color: #eeeeee;
  padding: 8px;
  padding-top: 86px;
  .score-container {
    &.new-ver {
      .post-item {
        height: 100%;
        margin-bottom: 0;
      }
    }
  }
  .stage-setting {
    .bottom-navigation {
      .nav-item {
        a {
          pointer-events: none;
          cursor: default;
        }
      }
    }
  }
  .upload-td {
    height: 195px;
    min-width: 185px;
  }
  .modal-action {
    position: absolute;
    width: 100%;
    bottom: 60px;
    display: flex;
    left: 0;
  }
  .btn-user-upload {
    position: unset;
    background-color: #d9d9d9;
    max-height: 260px;
    margin-top: 10px;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
    .input-container {
      position: relative;

      .btn-add {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      svg {
        position: unset;
        left: unset;
        top: unset;
        transform: unset;
      }
      // input {
      //   position: unset;
      // }
    }
  }
  .btn-add {
    margin-top: auto;
    margin-bottom: auto;
  }
  .active {
    font-weight: 600;
  }
  table {
    td {
      padding: 10px;
      text-align: left;
      width: 60px;
    }
    th {
      padding: 10px;
      text-align: left;
    }
  }
  .timeline-header {
    border-radius: 8px;
    margin: 8px;
    width: calc(100% - 16px);
    z-index: 9999999;
  }

  .post-item {
    &:after {
      filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%)
        hue-rotate(292deg) brightness(109%) contrast(87%);
    }
    &:before {
      filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%)
        hue-rotate(292deg) brightness(109%) contrast(87%);
    }
  }
  .nav {
    position: fixed;
    top: 86px;
    background: #fff;
    z-index: 9;
    padding: 10px 12px;
    border-radius: 8px;
    width: 300px;
    max-width: 300px;
    nav {
      overflow-y: auto;
      max-height: 80vh;
    }
  }
  .gacha-item-container {
    width: fit-content;
    margin: auto;
  }
  .gacha-item {
    background: #ffffff;
    border-radius: 8px;
    padding: 10px 20px;
    label {
      font-weight: 600;
    }
    h2 {
      font-size: 20px;
      font-weight: 800;
      white-space: nowrap;
      margin-right: 10px;
    }
    &.toy-detail-modal {
      width: 100%;
      position: relative;
      padding: 0;
    }

    // .toy-container {
    //   display: flex;
    //   overflow-x: auto;
    // }
  }
  .stage-setting-modal {
    .shadow-lg {
      box-shadow: none;
    }
    .bg-white {
      background: none;
    }
    .sub-modal {
      background: #ffffff;
      border-radius: 8px;
      min-width: 220px;
      max-width: 320px;
    }
  }
  .ar-preview {
    overflow: hidden;
    &.gacha-item {
      border-radius: 0;
      position: relative;
      height: 100%;
      padding: 0;
      min-width: 320px;
    }
    .bottom-navigation {
      position: absolute;
      left: 0;
    }
    .brg-image {
      height: 100%;
      img {
        object-fit: cover;
        height: 100%;
      }
    }
  }
}

.stage-setting-content {
  margin-left: 300px;
  padding: 12px;
}
