.page-header {
  position: relative;
  background-size: cover;
  width: 100%;
  height: 100px;
}
.image-layer {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 92.71%
  );
}
.bg-image {
  object-fit: cover;
  z-index: 1;
  width: 100%;
  height: 100px;
}

.btn-user-edit {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 4px;
  margin-top: 9px;
  margin-right: 8px;
  background: #ffffff;
  border-radius: 100%;
  height: 32px;
  width: 32px;
  z-index: 2;
}
.btn-user-upload {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  // transform: translate(-50%, -50%);
}
.user-tab {
  .user-tab-item {
    text-align: center;
    .tab-label {
      font-size: $text-sm;
      color: $color-secondary;
      font-weight: 600;
    }
  }
  .user-tab-item.active .tab-label {
    color: #000000;
  }
}
.score-container {
  .post-item {
    &:after {
      content: none;
    }
    &:before {
      content: none;
    }
  }
}
.image-frame {
  position: absolute;
  width: 100%;
  height: 100%;
  img {
    object-fit: fill !important;
    width: 100%;
  }
}
.score-container.new-ver {
  .post-item {
    width: 130px;
    min-width: 130px;
    height: 165px;
    margin-right: 20px;
    object-fit: contain;
    position: relative;
    img {
      border-radius: 0;
      height: 100%;
      width: 100%;
    }
  }
  .toy-container {
    display: flex;
    overflow-x: auto;
  }
}
.stage-prize {
  margin-bottom: 46px;
  .line-up {
    display: flex;
    overflow-x: auto;
    .post-item {
      height: 102px;
      margin-right: 9px;
      position: relative;
      text-align: center;
      max-width: 70px;
      min-width: 70px;
      img {
        border-radius: unset;
        height: 100%;
      }
      &::after {
        content: none;
      }
      &::before {
        content: none;
      }
    }
  }
}
.score-container.ver-2 {
  .toy-container {
    display: flex;
    overflow-x: auto;
    .post-item {
      margin-right: 9px;
      text-align: center;
      min-width: 60px;
      img {
        height: 60px;
        width: 60px;
        border-radius: 50%;
        margin-bottom: 5px;
      }
    }
  }
}
.score-tab {
  &.post-container {
    .post-item {
    }
  }
}
.post-container {
  color: #ffffff;
  // margin-bottom: 50px;
  .post-item {
    position: relative;
    margin-bottom: 4px;
    overflow: hidden;
    img {
      height: 160px;
      width: 100%;
    }
    .image-layer {
      border-radius: 8px;
      width: 100%;
      height: 160px;
      background: rgba(0, 0, 0, 0.7);
      position: absolute;
      opacity: 0.7;
      z-index: 0;
    }
  }
  .post-content {
    position: absolute;
    top: 0;
    padding: 3px;
    font-weight: 800;
    .post-title {
      font-size: 24px;
      max-width: 80%;
      line-height: 36px;
      font-family: "Hiragino Kaku Gothic Std";
    }
    .post-time {
      font-size: 10px;
      margin-bottom: 5px;
    }
  }
  .post-footer {
    position: absolute;
    bottom: 4px;
    left: 5px;
    width: 100%;
    .post-location {
      font-size: 10px;
      font-weight: 800;
    }
  }
}
.post-action {
  margin-left: auto;
  margin-right: 70px;
  display: flex;
  span {
    font-size: 10px;
  }
  .post-action-item {
    display: flex;
    margin-right: 9.75px;
    svg {
      margin-right: 2px;
    }
    &.right {
      position: absolute;
      right: 17px;
      bottom: 50px;
      display: block;
      margin: 0;
    }
  }
}
.user-info-item {
  display: flex;
  padding: 12px 24px;
  .svg-icon {
    margin-right: 15px;
    max-width: 24px;
    max-height: 24px;
    img {
      margin-right: -15px;
    }
  }
  button {
    margin-left: auto;
    svg {
      margin-left: 6.25px;
    }
  }
}

.point-container {
  margin-left: -4px;
  margin-right: -4px;
  .user-info-item {
    .title {
      font-weight: 600;
      flex: 0 0 40%;
      max-width: 40%;
    }
  }
}

.wallet {
  text-align: center;
  margin-top: 5px;
  margin-bottom: 12px;
  h3 {
    font-size: 24px;
    font-weight: 600;
  }
  p {
    font-size: 12px;
    color: #aaaaaa;
  }
}
.option-input {
  margin-top: 5px;
  .option {
    font-size: $text-sm;
    margin: auto;
    color: #aaaaaa;
    &.active {
      font-weight: 600;
      color: #000000;
    }
  }
}

.user-name-container {
  position: relative;
  margin-bottom: 30px;
  button {
    position: absolute;
    right: 24px;
    top: 60%;
    -ms-transform: translateY(-60%);
    transform: translateY(-60%);
  }
}
