.nav-item {
  &.active svg path {
    stroke: $color-danger;
  }
}
.bottom-navigation {
  height: 38px;
}
.bottom-navigation {
  border-top: 0.3px solid rgba(170, 170, 170, 1);
  background: #fff;
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 100;
  .nav-item {
    padding-left: 20px;
    padding-right: 20px;
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox; /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex; /* NEW, Spec - Firefox, Chrome, Opera */

    justify-content: center;
    align-items: center;
  }
}
.hub-action {
  z-index: 1;
  position: absolute;
  bottom: 64px;
  color: #fff;
  width: 100%;
  padding-left: 12px;
  .post-title {
    max-width: 60%;
    margin-bottom: 40px;
  }
}
.action-item {
  display: flex;
  align-items: center;

  img {
    height: 32px;
    width: 32px;
    margin-right: 5px;
    margin-top: 5px;
    border-radius: 100%;
    object-fit: cover;
  }
  span {
    font-size: $text-sm;
  }
  button {
    margin-left: auto;
    margin-right: 22.5px;
  }
}
