.form-group {
  margin-bottom: 10px;
  position: relative;
  label {
    font-size: $text-sm;
    font-weight: 600;
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
  &.p-end {
    .form-control {
      padding-left: 0 !important;
      padding-right: 38px;
    }
    .input-group-addon {
      right: 0;
    }
  }
  &:has(.input-group-addon) .form-control {
    padding-left: 38px;
  }
  .input-group-addon {
    position: absolute;
    border: 0;
    background: transparent;
    top: 50%;
    transform: translate(0, -50%);
  }
  .form-control {
    border: 0;
    background-image: linear-gradient($color-primary, $color-primary),
      linear-gradient(#000000, #000000);
    background-size: 0 2px, 100% 1px;
    background-repeat: no-repeat;
    background-position: center bottom, center calc(100% - 1px);
    background-color: transparent;
    transition: background 0s ease-out;
    float: none;
    box-shadow: none;
    border-radius: 0;
    &:focus {
      outline: none;
      background-image: linear-gradient($color-primary, $color-primary),
        linear-gradient(#000000, #000000);
      background-size: 100% 2px, 100% 1px;
      box-shadow: none;
      transition-duration: 0.3s;
    }
  }
  &.textarea {
    .form-control {
      height: unset;
      overflow: hidden;
      &:hover {
        overflow-y: scroll;
      }
    }
    .input-group-addon {
      top: unset;
      bottom: 0;
    }
  }
}
.slider {
  display: flex;
  align-items: center;
  div:nth-of-type(1) {
    margin-left: 10px;
    margin-bottom: 5px;
    // max-width: calc(100% - 50px);
  }
  .slider-input {
    width: 100%;
  }
  .form-control {
    width: 70px;
    margin-right: 10px;
    text-align: center;
    margin-bottom: 16px;
    // background: none;
    font-size: 20px;
    // &:focus {
    //   outline: none;
    //   background: none;
    // }
  }
}

.form-control {
  height: 24px;
  padding: 3px 0;
  font-size: $text-sm;
  line-height: 18px;
  display: block;
  width: 100%;
}

.input-container {
  width: 100%;
  height: 100%;
  input {
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    position: absolute;
  }
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0;
    left: 0;
  }
  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
