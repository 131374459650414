$color-primary: #2c84ac;
$color-secondary: #aaaaaa;
$color-success: #73a345;
$color-warning: #d49c44;
$color-danger: #a42464;

$color-primary-d1: #6b8c9b;
$color-primary-d2: #458bab;

$gradient-primary: linear-gradient(219.66deg, #c80064 19.79%, #0095d8 82.04%);
$gradient-green: linear-gradient(239.86deg, #d49c44 28.05%, #72e800 74.59%);
$gradient-brown: linear-gradient(219.66deg, #0095d8 19.79%, #d49c44 82.04%);

$text-sm: 12px;
$text-md: 16px;
$text-lg: 24px;
